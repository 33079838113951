<template>
  <div>
    <FlashMessage :error="error" class="text-center" />
    <div class="col-md-6" style="margin: 0 auto">
      <h3 class="text-center">Role Management</h3>
      <table class="table table-striped table-bordered table-dark">
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="role in roles" :key="role.id">
            <td>{{ role.name }}</td>
            <td>
              <button class="btn-sm btn-secondary">Show</button>
              <router-link :to="{name:'editrole', params:{id: role.id}}" class="btn-sm btn_primary">Edit Role</router-link> 
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getError } from "@/utils/helpers";
import FlashMessage from "@/components/FlashMessage";
import SuperadminService from "@/services/SuperadminServices";

export default {
  name: "roles",
  components: {
    FlashMessage,
  },
  data() {
    return {
      roles: [],
      error: null,
    };
  },
  mounted() {
    try {
      SuperadminService.getRoles().then((response) => {
        this.roles = response.data.roles;
      });
    } catch (error) {
      this.error = getError(error);
    }
  },
};
</script>