import * as API from "./API";

export default {
    getRoles(){
       return API.apiClient.get('roles', {
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
        }
      });
    }
}